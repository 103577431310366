import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Landing from "../components/landing/landing"
import CampaignClosed from "../components/campaign-closed/campaignClosedEn"

const IndexPage = () => {
  return (
    <>
      <Layout>
        <Seo title="Home | Snickers Hunger Insurance" lang="en" />
        {/* Campaign is On */}
        {/* <Landing /> */}
        {/* Campaign Closed */}
        <CampaignClosed />
      </Layout>
    </>
  )
}

export default IndexPage

// import React, { useEffect } from "react";
// import Layout from "../components/layoutAr";
// import Landing from "../components/landing/landingAr";
// import Seo from "../components/seoAr";
// import { navigate } from "gatsby";

// const IndexPage = (props) => {
//   console.log(props);
//   useEffect(() => {
//     if (props.path.startsWith("/")) {
//       document.querySelector("body").classList.add("rightToLeft");
//       document.querySelector("body").classList.add("arabic-fonts");
//     }
//     // navigate("/stores");
//     if (typeof window !== "undefined") {
//       window.addEventListener("message", function (event) {
//         if (event.data === "file uploaded") {
//           setTimeout(() => {
//             document.querySelector(".uploadForm").classList.remove("show");
//             document.querySelector("body").classList.remove("overflow-lock");
//             const dfMessenger = document.querySelector("df-messenger");

//             dfMessenger.renderCustomText(
//               `
// Your file had been uploaded successfully
// تم تحميل ملفك بنجاح.`,
//               true
//             );

//             dfMessenger.renderCustomText(
//               `
// Great news! I’ve run the numbers and you’re entitled to a discounted Snickers! could you provide email address to complete the transaction.
// أخبار رائعة! لقد قمت بتشغيل الأرقام ويحق لك الحصول على سنيكرز بسعر مخفض! هل يمكنك تقديم عنوان البريد الإلكتروني لإكمال المعاملة.`,
//               true
//             );
//             const payload = [
//               {
//                 options: [
//                   {
//                     mode: "blocking",
//                     text: "YES نعم",
//                   },
//                   {
//                     mode: "blocking",
//                     text: "NO كلا",
//                   },
//                 ],
//                 type: "chips",
//               },
//             ];
//             dfMessenger.renderCustomCard(payload);
//           }, 1000);
//         }
//       });
//     }
//     document.addEventListener("df-chip-clicked", (event) => {
//       if (event.detail.text.startsWith("Click here to upload")) {
//         // document.querySelector("h1").style.color = "red";
//         document.querySelector(".uploadForm").classList.add("show");
//         window.scroll(0, 0);
//         document.querySelector("body").classList.add("overflow-lock");
//         console.log(event);
//       }
//     });
//   }, []);

//   return (
//     <>
//       <Layout>
//         <Seo title="الصفحة الأساسية | تأمين سنكرس ضدّ الجوع" lang="ar" />
//         {/* Campaign is On */}
//         <Landing />
//         <div className="uploadForm">
//           <iframe
//             srolling="no"
//             src="https://evidence-upload-fuv6o3wcha-uc.a.run.app/"
//           ></iframe>
//         </div>
//         <df-messenger
//           project-id="snickersinsurancechatbot2-ixwh"
//           agent-id="1ec78602-0cb1-448f-8a4e-779685238fc7"
//           language-code="ar"
//         >
//           <df-messenger-chat-bubble chat-title="Hunger Insurance Chat Bot"></df-messenger-chat-bubble>
//         </df-messenger>
//         {/* Campaign Closed */}
//         {/* <CampaignClosed /> */}
//       </Layout>
//     </>
//   );
// };

// export default IndexPage;
