import React from "react"
import { Link } from "gatsby"

import Footer from "../footer/footer"

import snickersLogo from "../../images/snickers-hunger-insurance.png"

const CampaignClosed = () => {
  return (
    <section className="agegate-hldr promotion-close pt d-flex justify-content-between flex-column align-items-center pt-5">
      <div className="d-flex flex-column justify-content-center align-items-center h-100 px-md-5 mx-md-5 px-3">
        <Link to="/">
          <figure className="logo">
            <img src={snickersLogo} alt="Snickers Logo" className="img-fluid" />
          </figure>
        </Link>
        <h1 className="text-white" style={{ maxWidth: "700px", margin: "0 auto" }}>
          Oops, our insurance policies have been exhausted, next time eat a Snickers and don't be so late. Visit our sites and stay tuned for upcoming news.
        </h1>
      </div>
      <Footer />
    </section>
  )
}

export default CampaignClosed
